<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Billing Dashboard</div>
    <div class="
        col-lg-6 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-12 billing-dashboard-scrollbar" style="height: calc(100vh - 141px)">
      <div>
        <div class="
          content-header-section
        ">
          <span class="plan-header-label d-flex justify-content-start align-items-center">
            <div class="me-2">Select Host : </div>
            <div class="custom-form-group mb-0 w-50">
              <Multiselect v-model="hostcompanyname" :options="hostcompanynamelist"
                @select="getHostDataByCompanyId(hostcompanyname)" :searchable="true" label="label"
                placeholder="Select Host Name" class="multiselect-custom text-capitalize" :canClear="false"
                :closeOnSelect="true" :object="true" />
            </div>
          </span>
        </div>
      </div>
      <div class="position-relative">
        <div v-if="!showdataloader">
          <div v-if="foundcompanydetailsflag">
            <div>
              <div class="
            content-header-section
            d-flex
            justify-content-between
            align-items-end
          ">
                <div>
                </div>
                <div>
                  <span class="box-subheader-label me-1">Activation Date :</span><span class="plan-header-label">
                    {{ (currentPlanInfo) ? format_date(currentPlanInfo.plan_active_datetime) : 'N/A' }}</span>
                </div>
              </div>
              <div class="billing-dashboard-detail-outer">
                <div class="row g-3">
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="service-plan-box-outer">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="plan-header-label">Main Balance</div>
                        <div class="chart-label">
                          {{
                vueNumberFormat(billingDetails.balaceAmount, {
                  prefix: "₹ ",
                  decimal: ".",
                  thousand: ",",
                  precision: 2,
                  acceptNegative: true,
                })
              }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-12">
                    <div class="service-plan-box-outer">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="plan-header-label">Minimum Balance Requirement is :</div>
                        <div class="chart-label">
                          {{
                  vueNumberFormat(billingDetails.minimumbalanceforactiveclient, {
                    prefix: "₹ ",
                    decimal: ".",
                    thousand: ",",
                    precision: 2,
                    acceptNegative: true,
                  })
                }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion" id="accordionCompanyBilling" v-if="companydetails">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingCompany">
                  <button class="accordion-button custom-accordion-focus-out-btn" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseCompanyBilling" aria-expanded="false"
                    aria-controls="collapseCompanyBilling">
                    Billing Company Details
                  </button>
                </h2>
                <div id="collapseCompanyBilling" class="accordion-collapse collapse" aria-labelledby="headingCompany"
                  data-bs-parent="#accordionCompanyBilling">
                  <div class="accordion-body">
                    <div class="row g-2">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group">
                          <label class="box-subheader-label">Legal Name</label>
                          <div class="plan-header-label text-capitalize">
                            {{ companydetails.hd3 ? companydetails.hd3 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group">
                          <label class="box-subheader-label">GST No</label>
                          <div class="plan-header-label text-uppercase">
                            {{ companydetails.hd6 ? companydetails.hd6 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group">
                          <label class="box-subheader-label">Pan Card</label>
                          <div class="plan-header-label text-uppercase">
                            {{ companydetails.hd5 ? companydetails.hd5 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-0">
                          <label class="box-subheader-label">Address</label>
                          <div class="plan-header-label text-capitalize">
                            {{ companydetails.hd7 ? companydetails.hd7 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-0">
                          <label class="box-subheader-label">Pincode</label>
                          <div class="plan-header-label text-capitalize">
                            {{ companydetails.hd13 ? companydetails.hd13 : "N/A" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <div class="
            content-header-section
            d-flex
            justify-content-start
            align-items-center
          ">
                <div>
                  <span class="plan-header-label d-flex align-items-center">
                    <div class="box-icon-inner d-flex justify-content-center me-2">
                      <img src="/assets/images/icon/feedback-day.svg" alt="" width="18" />
                    </div>
                    <div>
                      Below Usage is till {{ yesterday }} till 24:00 Hours.
                    </div>
                  </span>
                </div>
              </div>
              <div class="billing-dashboard-detail-outer mb-0">
                <div class="row g-3 d-flex justify-content-center">
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="feedback-small-box-outer">
                      <div class="box-icon">
                        <div class="box-icon-inner d-flex justify-content-center">
                          <img src="/assets/images/icon/feedback-pending.svg" alt="" width="22" />
                        </div>
                      </div>
                      <div class="box-label">Existing Guests</div>
                      <div class="box-points-outer d-flex align-items-center">
                        <div class="rating-value">{{ billingDetails.existingVisitorCount }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-3 col-12">
                    <div class="feedback-small-box-outer">
                      <div class="box-icon">
                        <div class="box-icon-inner d-flex justify-content-center">
                          <img src="/assets/images/icon/feedback-pending.svg" alt="" width="22" />
                        </div>
                      </div>
                      <div class="box-label">New Guests</div>
                      <div class="box-points-outer d-flex align-items-center">
                        <div class="rating-value">{{ billingDetails.newVisitorCount }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showdataloader" style="height: 430px">
          <div class="custom-modal-spinner-loader">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import ApiService from "../../../service/ApiService";
import moment from 'moment';
export default {
  data() {
    return {
      v$: useValidate(),
      billingDetails: "",
      currentPlanInfo: "",
      yesterday: new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).toLocaleDateString(),
      showdataloader: false,
      addfunctionmodalstatus: false,
      companydetails: "",
      hostcompanyname: '',
      hostcompanynamelist: [],
      foundcompanydetailsflag: false,
    };
  },
  validations() {
    return {
    };
  },
  mounted() {
    this.getHostListValues();
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  methods: {
    getHostListValues(e) {
      this.ApiService.getHostListValues(e).then((data) => {
        if (data.success == true) {
          this.hostcompanynamelist = data.data;
        } else {
          this.hostcompanynamelist = null;
        }
      });
    },
    getHostDataByCompanyId(e) {
      this.getHostBillingDashboardData({ ac1: e.value });
    },
    getHostBillingDashboardData(e) {
      this.showdataloader = true;
      this.ApiService.getHostBillingDashboardData(e).then((data) => {
        if (data.success == true) {
          this.foundcompanydetailsflag = true;
          this.billingDetails = data.data;
          this.currentPlanInfo = data.data.currentPlanInfo;
          this.companydetails = data.data.companydetails;
          this.showdataloader = false;
        } else {
          this.foundcompanydetailsflag = false;
          this.showdataloader = false;
          this.billingDetails = "";
          this.currentPlanInfo = "";
          this.companydetails = "";
        }
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
  },
};
</script>

<style scoped>
.billing-dashboard-detail-outer {
  padding: 16px;
  margin-bottom: 20px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
  position: relative;
}

.billing-dashboard-detail-outer .custom-col-3 {
  width: 20%;
}

.content-header-section {
  padding: 12px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  background: #ffffff 0% 0% no-repeat padding-box;
}

.plan-header-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
  user-select: none;
}

.box-subheader-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  user-select: none;
}

.service-plan-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 16px;
}

.account-details-box-outer {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 16px;
}

.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .box-subheader-label {
  margin-bottom: 6px;
}

.service-plan-box-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.add-fund-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 15px;
  font-family: "AcuminPro-Regular";
  height: 34px;
}

.add-fund-btn.btn:hover,
.account-statement-link-btn:hover {
  box-shadow: none !important;
}

.account-statement-link-btn {
  padding: 0;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  letter-spacing: 0.24px;
  color: #5266e5;
  line-height: 12px;
  text-decoration: none;
}

.box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  user-select: none;
  pointer-events: none;
}

.custom-accordion-focus-out-btn {
  user-select: none;
}

.custom-accordion-focus-out-btn:focus {
  box-shadow: none;
}

.plan-service-small-box-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  user-select: none;
}

.plan-service-small-box-outer:hover {
  -moz-transform: scale(1.04);
  -webkit-transform: scale(1.04);
  -o-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  border: 1px solid #b7bfc9;
}

.plan-service-small-box-outer .box-icon .box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  user-select: none;
  pointer-events: none;
}

.plan-service-small-box-outer .box-label {
  letter-spacing: 0.26px;
  color: #4a5463;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  text-align: left;
  line-height: 13px;
  padding-top: 16px;
  user-select: none;
}

.plan-service-small-box-outer .box-points-outer {
  user-select: none;
  padding-top: 16px;
}

.plan-service-small-box-outer .service-type-name {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}

.plan-service-small-box-outer .service-type-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 14px;
}

.plan-service-small-box-outer .plan-service-name {
  font-family: "AcuminPro-Bold";
  font-size: 15px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 15px;
}

.billing-dashboard-scrollbar {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
}

.billing-dashboard-scrollbar::-webkit-scrollbar,
.billing-plan-table-scroll-outer::-webkit-scrollbar {
  display: block;
  width: 0.3em;
  height: 0.3em;
}

.billing-dashboard-scrollbar::-webkit-scrollbar-thumb,
.billing-plan-table-scroll-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.billing-plan-table-scroll-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 513px;
}

.billing-plan-table-scroll-outer {
  overflow: hidden;
  display: block;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 105px) !important;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
  user-select: none;
}

.billing-plan-table-scroll-outer table {
  width: 100%;
}

.billing-plan-table-scroll-outer .custom-header-outer {
  position: sticky;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  top: 0;
  z-index: 10;
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px #e0e5ed;
  color: #4a5463;
  background: #f2f4fb;
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  line-height: 13px;
  font-weight: 400;
  user-select: none;
}

.billing-plan-table-scroll-outer td {
  padding: 8px;
  padding: 10px 6px;
  border: 1px solid #e0e5ed;
}

.addfundcardouter .addfunheader {
  font-family: "AcuminPro-Bold";
  font-size: 16px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 19px;
}

.addfundcardouter .service-type-name {
  font-family: "AcuminPro-Regular";
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 20px;
  width: 130px;
}

.addfundcardouter .service-type-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.feedback-small-box-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 16px 20px;
}

.feedback-small-box-outer .box-icon .box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.feedback-small-box-outer .box-label {
  letter-spacing: 0.26px;
  color: #4a5463;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  text-align: left;
  line-height: 13px;
  padding-top: 16px;
}

.feedback-small-box-outer .box-points-outer {
  padding-top: 10px;
}

.feedback-small-box-outer .box-points-outer .rating-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 25px;
  letter-spacing: 0.5px;
  color: #2b2e38;
  text-align: left;
  line-height: 25px;
}

.feedback-small-box-outer .box-points-outer .rating-graph-up {
  font-family: "AcuminPro-SemiBold";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.26px;
  color: #479d21;
  text-align: left;
  padding-left: 20px;
}
</style>
